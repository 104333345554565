import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  EnvironmentInjector,
  Injectable,
  InputSignal,
  RendererFactory2,
  Type,
  createComponent,
  inject,
} from '@angular/core';

interface Modal {
  show: () => void;
  title: string | InputSignal<string>;
}

interface NoTitleModal {
  show: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class BasicModalService {
  private readonly document = inject(DOCUMENT);
  private readonly renderer = inject(RendererFactory2).createRenderer(null, null);
  private readonly environmentInjector = inject(EnvironmentInjector);
  private readonly applicationRef = inject(ApplicationRef);

  /**
   * Displays a modal component with a title.
   *
   * @param ModalComponent The type of modal you want to display. Must
   * follow the defintion of the {@link Modal} type in order to be displayed
   * @param title A string value to be displayed in the modal header
   */
  showWithTitle<T extends Modal>(ModalComponent: Type<T>, title: string): void {
    if (this.isModalOpen()) return;
    const modalHost = this.renderer.createElement('div');
    this.renderer.appendChild(this.document.body, modalHost);
    const modal = createComponent(ModalComponent, {
      hostElement: modalHost,
      environmentInjector: this.environmentInjector,
    });
    modal.setInput('title', title);
    this.applicationRef.attachView(modal.hostView);
    modal.changeDetectorRef.detectChanges();
    modal.instance.show();
  }

  /**
   * Displays a basic modal component **without** a title. To display a modal
   * with a title, use {@link showWithTitle} instead.
   *
   * @param ModalComponent The type of modal you want to display. Must
   * follow the definition of the {@link NoTitleModal} type in order to be displayed
   */
  show<T extends NoTitleModal>(ModalComponent: Type<T>): void {
    if (this.isModalOpen()) return;
    const modalHost = this.renderer.createElement('div');
    this.renderer.appendChild(this.document.body, modalHost);
    const modal = createComponent(ModalComponent, {
      hostElement: modalHost,
      environmentInjector: this.environmentInjector,
    });
    this.applicationRef.attachView(modal.hostView);
    modal.changeDetectorRef.detectChanges();
    modal.instance.show();
  }

  private isModalOpen(): boolean {
    return !!this.document.querySelector('.fly-modal-open');
  }
}
