import { Injectable, inject, signal } from '@angular/core';
import {
  LocalStorageKey,
  LocalStorageService,
  type OAuthTokenResponse,
} from '@garmin-avcloud/avcloud-web-utils';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { UserSettingsDualSyncControllerService } from '@generated/flight-orchestrator-service';
import { FeatureFlag } from '@shared/enums/feature-flag.enum';
import { type Observable, map, of, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FlightOrchestratorFlagsService {
  private readonly localStorageService = inject(LocalStorageService);
  private readonly featuresService = inject(FeaturesService);
  private readonly userSettingsDualSyncControllerService = inject(
    UserSettingsDualSyncControllerService,
  );

  readonly flightsFeaturesUnlocked = signal(false);
  readonly aircraftFeaturesUnlocked = signal(false);

  private pendingStatus: Observable<undefined> | null = null;

  load(): Observable<undefined> {
    if (this.pendingStatus != null) return this.pendingStatus;

    this.pendingStatus = this.featuresService
      .isFeatureActive(FeatureFlag.FLIGHT_ORCHESTRATOR_FLAGS)
      .pipe(
        switchMap((enabled) => {
          if (enabled) {
            const customerGuid = this.localStorageService.get<OAuthTokenResponse>(
              LocalStorageKey.OAuthTokenResponse,
            )?.customerId;
            if (customerGuid == null || customerGuid.length === 0) {
              throw new Error('Cannot load sync flags without valid customer ID');
            }

            return this.userSettingsDualSyncControllerService
              .getDualSyncEffectiveStatus(customerGuid)
              .pipe(
                map((resp) => {
                  const dualSync = resp.dualSyncing?.effectiveEnabledValue ?? false;
                  const orchestratorGoverning =
                    resp.phoenixResultGoverning?.effectiveEnabledValue ?? false;
                  const flightDomainOnly = resp.phoenixSyncOnly?.effectiveEnabledValue ?? false;
                  this.flightsFeaturesUnlocked.set(flightDomainOnly);
                  this.aircraftFeaturesUnlocked.set(
                    dualSync && (orchestratorGoverning || flightDomainOnly),
                  );
                  return undefined;
                }),
              );
          }

          // feature disabled
          this.flightsFeaturesUnlocked.set(true);
          this.aircraftFeaturesUnlocked.set(true);
          return of(undefined); // need to emit a value so we can actually test this
        }),
        shareReplay(1),
      );

    return this.pendingStatus;
  }
}
